<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("creatediscount") }}
        </h3>
      </div>
      <div class="card-body">
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">{{
              $t("discountscode")
            }}</label>
            <input v-model="form.code" class="form-control mb-4" />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">{{
              $t("discountsname")
            }}</label>
            <input v-model="form.name" class="form-control mb-4" />
          </div>
          <!-- <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">valid from</label>
            <input v-model="valid" class="form-control mb-4" type="date" />
          </div> -->
        </div>
      </div>
      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit()"
          >
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>
<script>
import promotionAPI from "@/api/promotion/";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  data: () => ({
    form: {
      code: "",
      name: "",
    },
    // valid: new Date(),
  }),
  created() {},
  methods: {
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.validateForm();
          await this.checkFormIsEmpty();
          let createResponse = [];
          this.loading = true;
          this.form = {
            ...this.form,
            companyId: parseInt(localStorage.getItem("companyId")),
          };
          this.isSubmit = true;
          if (this.isValid) {
            createResponse = await promotionAPI.discount.create(this.form);
            if (createResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: "เพิ่มสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push("/promotion/discount/index");
              });
              this.loading = false;
            } else {
              Swal.fire({
                icon: "error",
                title: "เพิ่มไม่สำเร็จ !",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            }
          }
        }
      });
    },
    checkFormIsEmpty() {
      !this.form.code
        ? (this.isValid = false)
        : !this.form.name
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>